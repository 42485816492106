import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BrokerageContactConfirmModal from '@client/components/BrokerageContactConfirmModal';
import CantFindAddressModal from '@client/components/CantFindAddressModal';
import ContactLoanOfficerModal from '@client/components/ContactLoanOfficerModal';
import ContactNewAgentModal from '@client/components/ContactNewAgentModal/ContactNewAgentModal';
import EmailOptInModal from '@client/components/EmailOptInModal';
import SessionKeepAlive from '@client/components/generic/SessionKeepAlive';
import Toast from '@client/components/generic/Toast';
import GrantProgramModal from '@client/components/GrantProgram/GrantProgramModal';
import HOHomeSalesSeeAllModal from '@client/components/HOHomeSalesSeeAllModal';
import HomeEditSuccessNotification from '@client/components/HomeEditSuccessNotification';
import HomeVerifiedNotification from '@client/components/HomeVerifiedNotification';
import InviteSearchAgreementModal from '@client/components/InviteSearchAgreementModal';
import LODirectLoginNavModal from '@client/components/LODirect/LODirectLoginNavModal';
import LODirectNotificationError from '@client/components/LODirect/LODirectNotificationError';
import LODirectNotificationSuccess from '@client/components/LODirect/LODirectNotificationSuccess';
import MLSNotifyConfirmModal from '@client/components/MLSNotifyConfirmModal';
import NoMLSModalCobranded from '@client/components/NoMLSModal/NoMLSModalCobranded';
import RecentUserActivityFlyout from '@client/components/RecentUserActivity/RecentUserActivityFlyout';
import { RelayStateActionConfirmationModal } from '@client/components/RelayStateActionConfirmationModal';
import RequestATourModal from '@client/components/RequestATour/RequestATourModal';
import RequestATourSuccessNotification from '@client/components/RequestATour/RequestATourSuccessNotification';
import ResendConfirmEmailSuccessModal from '@client/components/ResendConfirmEmailSuccessModal';
import SavedPropertyEmailSettingNotification from '@client/components/SavedPropertyEmailSettingNotification';
import SavedSearchEmailSettingNotification from '@client/components/SavedSearchEmailSettingNotification';
import SavedSearchNotification from '@client/components/SavedSearchNotification';
import SharedPropertySavedEmailSettingNotification from '@client/components/SharedPropertySavedEmailSettingNotification';
import SharedPropertySavedNotification from '@client/components/SharedPropertySavedNotification';
import TermsAndConditionsModalCobranded from '@client/components/TermsAndConditionsModal/TermsAndConditionsModalCobranded';
import YourTeamModal from '@client/components/YourTeam/YourTeamModal';
import YourTeamTriggerButton from '@client/components/YourTeam/YourTeamTriggerButtonCobranded';
import AuthModalContainer from '@client/containers/auth-modal.container';
import BrokerageModalContainer from '@client/containers/brokerage-modal.container';
import UpdateEmailModalContainer from '@client/containers/update-email-modal.container';
import { getModalTargetDOMId } from '@client/hocs/create-modal-portal';
import renderOnMountUntyped from '@client/hocs/render-on-mount-untyped';
import { setModalPortalKeys } from '@client/store/actions/modals.actions';
import {
  MODAL_KEYS_TO_RENDER_IN_PORTAL,
  ModalKeyToRenderInPortal,
} from '@client/store/constants';
import { getIsFourHundredZoom } from '@client/store/selectors/match-media.selectors';
import { getIsShowingAlternateYourTeamTriggerButton } from '@client/store/selectors/modals.selectors';
import { localStorageUtil } from '@client/utils/local-storage.utils';

const PortalDestination = ({
  modalKey,
}: {
  modalKey: ModalKeyToRenderInPortal;
}) => <div data-hc-name={modalKey} id={getModalTargetDOMId(modalKey)} />;

/**
 * A high-level component in which we define modals directly and define portals for rendering modals into from
 * lower in the tree using the `create-modal-portal` HOC.  If the modal relies solely on Redux state for its
 * data, the modal should be defined here directly.  If it needs data from a React component, it can be
 * defined in that component and rendered using the aforementioned HOC.
 */
const Modals: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const isAlternateYourTeamTriggerButtonShowing = useSelector(
    getIsShowingAlternateYourTeamTriggerButton
  );

  const isFourHundredPercentZoom = useSelector(getIsFourHundredZoom);

  /* Disabling the session keep-alive functionality by default when running app locally in development mode */
  const shouldAllowSessionKeepAlive =
    process.env.NODE_ENV === 'production' ||
    /* eslint-disable-next-line custom/explain-localstorage */
    !!localStorageUtil.getItem('enableSessionKeepAlive');

  /* Only run after initial render */
  useEffect(() => {
    dispatch(setModalPortalKeys(MODAL_KEYS_TO_RENDER_IN_PORTAL.slice(0)));
  }, []);

  return (
    <aside>
      {/**
       * Typical modals where stacking order in relation to other modals doesn't matter.
       * Order matters less here since these modals won't ever be displayed on top of other modals
       */}
      {
        /* If we're not showing the "alternate" YT button on the mobile PDP then show this global button
         *  If we are not at 400% zoom show this button. Otherwise show button in Footer component. */
        !isAlternateYourTeamTriggerButtonShowing &&
          !isFourHundredPercentZoom && <YourTeamTriggerButton />
      }
      <GrantProgramModal /> {/* No modal key - dedicated Redux selector */}
      <RecentUserActivityFlyout /> {/* modalKey'recent-user-activity'*/}
      <YourTeamModal /> {/* No modal key - dedicated Redux selector */}
      <Toast /> {/* No modal key - dedicated Redux selector */}
      <UpdateEmailModalContainer /> {/* modalKey='update-email-modal' */}
      <ResendConfirmEmailSuccessModal />
      {/* modalKey='resend-confirm-email-success-modal' */}
      <ContactLoanOfficerModal /> {/* modalKey='contact-loan-officer' */}
      <InviteSearchAgreementModal />
      {/* modalKey='invite-search-agreement-modal' */}
      <RelayStateActionConfirmationModal />
      {/* modalKey='confirm-add-watchlist-modal', `confirm-claim-home-modal` */}
      <NoMLSModalCobranded />
      {/* No modal key - dedicated Redux selector */}
      <MLSNotifyConfirmModal />
      {/* No modal key - dedicated Redux selector */}
      <BrokerageContactConfirmModal />
      {/* No modal key - dedicated Redux selector */}
      <BrokerageModalContainer />
      {/* No modal key - dedicated Redux selector */}
      <EmailOptInModal /> {/* No modal key - dedicated Redux selector */}
      <PortalDestination modalKey="local-activities-modal" />
      <PortalDestination modalKey="avm-deep-dives-modal" />
      <PortalDestination modalKey="brokerage-modal" />
      <PortalDestination modalKey="comps-modal" />
      <PortalDestination modalKey="mobile-filters-modal" />
      <PortalDestination modalKey="property-details" />
      <PortalDestination modalKey="photo-list" />
      <PortalDestination modalKey="photo-carousel-dialog" />
      <PortalDestination modalKey="homeowner-avm-accessible-chart" />
      <PortalDestination modalKey="homeowner-home-value" />
      <PortalDestination modalKey="homeowner-home-improve" />
      <PortalDestination modalKey="homeowner-compsales-modal" />
      <PortalDestination modalKey="homeowner-access-modal" />
      <PortalDestination modalKey="homeowner-search-modal" />
      <PortalDestination modalKey="find-agent-success-modal" />
      <PortalDestination modalKey="find-agent-error-modal" />
      <PortalDestination modalKey="cta-agent-form" />
      <PortalDestination modalKey="partner-agent-form" />
      <PortalDestination modalKey="homeowner-propensity-to-sell" />
      <PortalDestination modalKey="pdp-mobile-breakout-section" />
      {/**
       * Modals needed for display on top of other modals.  Order matters more here.
       */}
      <CantFindAddressModal />
      <RequestATourModal />
      {/* Needs to be on top of homeowner-search-modal */}
      <PortalDestination modalKey="confirm-modal" />
      <PortalDestination modalKey="cho-pro-finder-reviews" />
      <ContactNewAgentModal /> {/* modalKey='contact-new-agent' */}
      <PortalDestination modalKey="share-modal" />
      {/* Needs to be on top of `photo-list` */}
      <TermsAndConditionsModalCobranded />
      {/* For SAML auth clients, this modal forces users to accept our terms and conditions prior to using the rest of the site */}
      {
        shouldAllowSessionKeepAlive && (
          <SessionKeepAlive />
        ) /* Needs to be on top of most other modals. */
      }
      {/* On top so that you can always login from within other modals */}
      <AuthModalContainer />
      {/* This will display after the auth modal closes in some situations, so should have the same ordering */}
      <LODirectLoginNavModal />
      {/* On top - it's a temporary modal that can be displayed within other modals, including auth */}
      <PortalDestination modalKey="tooltip" />
      {/* homeowner-home-sales-see-all or homeowner-home-sales-map-view */}
      <HOHomeSalesSeeAllModal />
      <PortalDestination modalKey="homeowner-pmi-modal" />
      {/* 🔔 Notification modals START 👇🏼 */}
      <SharedPropertySavedEmailSettingNotification />
      <SharedPropertySavedNotification />
      <SavedPropertyEmailSettingNotification />
      <SavedSearchEmailSettingNotification />
      <SavedSearchNotification />
      <HomeVerifiedNotification />
      <HomeEditSuccessNotification />
      <RequestATourSuccessNotification />
      <LODirectNotificationSuccess />
      <LODirectNotificationError />
      {/* 🔔 Notification modals END 👆🏼 */}
    </aside>
  );
};

export default renderOnMountUntyped(Modals);
