import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PillButton from '@client/components/generic/PillButton';
import TextButton from '@client/components/generic/TextButton';
import PulseLoader from '@client/components/PulseLoader';
import FindAgentForm from '@client/components/YourTeam/FindAgentForm';
import { MessageSuccess } from '@client/components/YourTeam/MessageConfirmation';
import {
  clearIsAgentFormPostedInCookie,
  getIsAgentFormPostedInCookie,
  setIsAgentFormPostedInCookie,
  useReportYourTeamEvent,
} from '@client/components/YourTeam/utils/your-team.utils';
import YourTeamCTAs from '@client/components/YourTeam/YourTeamCTAs';
import PropertyContactFormContainer from '@client/containers/property-contact-form.container';
import theme from '@client/css-modules/YourTeam/AgentCard.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import { useYourTeamAgentData } from '@client/hooks/your-team.hooks';
import FindAgentAvatarDark from '@client/inline-svgs/find-agent-avatar-dark';
import { ensureLoggedInThenAction } from '@client/store/actions/auth.actions';
import { STATUSES } from '@client/store/constants';
import {
  getCurrentUser,
  getIsLoggedIn,
} from '@client/store/selectors/auth.selectors';
import { getYourTeam } from '@client/store/selectors/cobranding.selectors';
import { getIsFeatureEnabled } from '@client/store/selectors/enabled-features.selectors';
import {
  deleteAgentSelection,
  postContactAgent,
  postLenderAgentSelection,
  postPreviousAgentSelection,
  resetContactAgentStatus,
  selectContactAgentMessageStatus,
} from '@client/store/slices/your-team.slice';
import { ReferralFormValues } from '@client/store/types/property-contact-form';
import {
  formatPhoneNumber,
  getStringWithDelimiter,
} from '@client/utils/string.utils';

const SELECT_AGENT_BUTTON_COPY = 'Use this agent';
const SELECT_PREVIOUS_AGENT_HEADER_COPY = 'Your previous agent';
const SELECT_LENDER_AGENT_HEADER_COPY = 'Lender recommended agent';
const GET_PAIRED_WITH_AN_AGENT_CTA_COPY = 'Get paired with an agent';
const CHOOSE_ANOTHER_AGENT_CTA_COPY = 'Choose another agent';

type AgentDisplayTypes =
  | 'agentForm'
  | 'pending'
  | 'generic'
  | 'introduced'
  | 'select'
  | 'userForm';
type ChooseAnotherAgentCTAProps = {
  agentDisplayType: AgentDisplayTypes;
  setAgentDisplayType: (displayType: AgentDisplayTypes) => void;
};

const computeAgentDisplayType = ({
  hasIntroducedAgentData,
  hasGenericAgentData,
  hasPendingAgentData,
  isAgentFormPostedInCookie,
  hasRecommendedAgents,
}: {
  hasIntroducedAgentData: boolean;
  hasGenericAgentData: boolean;
  hasPendingAgentData: boolean;
  isAgentFormPostedInCookie: boolean;
  hasRecommendedAgents: boolean;
}) =>
  /* priority 1 - Pipedrive stage3+ introduced agent OR intro_required=false agent
   * (i.e. agentid in URL or partner-api associated) */
  hasIntroducedAgentData
    ? 'introduced'
    : /* priority 2 - selected agent or agentForm submitted */
      hasGenericAgentData
      ? 'generic'
      : // check for generic here
        hasPendingAgentData || isAgentFormPostedInCookie
        ? 'pending'
        : /* priority 3 - has recommended agents to select */
          hasRecommendedAgents
          ? 'select'
          : /* priority 4 - choose your own agent form */
            'agentForm';

function ChooseAnotherAgentCTA({
  agentDisplayType,
  setAgentDisplayType,
}: ChooseAnotherAgentCTAProps) {
  const reportYourTeamEvent = useReportYourTeamEvent();
  const { linkColor } = useCobrandStyles();
  const { previousAgentData, lenderAgentData } = useYourTeamAgentData();
  const hasRecommendedAgents = !!(lenderAgentData || previousAgentData);
  return (
    <TextButton
      style={{ color: linkColor }}
      dataHcName="choose-another-agent-button"
      className={classNames(theme.ChooseAnotherAgentCTA, {
        [theme.CenteredCTA]: agentDisplayType === 'pending',
      })}
      onClick={() => {
        reportYourTeamEvent(
          /* case 1: unsubmitted find an agent form */
          agentDisplayType === 'agentForm'
            ? 'click_your_team_choose_another_agent'
            : `click_your_team_choose_another_agent_${
                /* case 2: agent selection pending state */
                agentDisplayType === 'pending'
                  ? 'confirmation'
                  : /* case 3: introduced agent */
                    'question'
              }_page`,
          {
            buttonCopy: CHOOSE_ANOTHER_AGENT_CTA_COPY,
          }
        );
        setAgentDisplayType(hasRecommendedAgents ? 'select' : 'agentForm');
      }}
    >
      {CHOOSE_ANOTHER_AGENT_CTA_COPY}
    </TextButton>
  );
}

export default function AgentCard() {
  const dispatch = useDispatch();
  const reportYourTeamEvent = useReportYourTeamEvent();
  const yourTeamCTAConfig = useSelector(getYourTeam);
  const isReferralServicesEnabled = useSelector(
    getIsFeatureEnabled('referral_services')
  );
  const user = useSelector(getCurrentUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { linkColor } = useCobrandStyles();
  const {
    previousAgentFetchStatus,
    lenderAgentFetchStatus,
    connectedAgentFetchStatus,
    introducedAgentData,
    pendingAgentData,
    previousAgentData,
    lenderAgentData,
    genericAgentData,
  } = useYourTeamAgentData();
  const userId = user?.id ?? null;
  const hasRecommendedAgents = !!(lenderAgentData || previousAgentData);
  const isAgentFormPostedInCookie = getIsAgentFormPostedInCookie(userId);
  const contactAgentMessageStatus = useSelector(
    selectContactAgentMessageStatus
  );
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);
  /* Handles displaying "Thanks!" text in pending state, which should display only after agent
   * was just selected in the Your Team UI, not after it's opened anew */
  const [wasAgentJustSelected, setWasAgentJustSelected] = useState(false);
  const [agentDisplayType, setAgentDisplayType] = useState<AgentDisplayTypes>(
    computeAgentDisplayType({
      hasIntroducedAgentData: !!introducedAgentData,
      hasGenericAgentData: !!genericAgentData,
      hasPendingAgentData: !!pendingAgentData,
      isAgentFormPostedInCookie,
      hasRecommendedAgents,
    })
  );

  useEffect(() => {
    if (connectedAgentFetchStatus === STATUSES.SUCCESS) {
      setAgentDisplayType(
        computeAgentDisplayType({
          hasIntroducedAgentData: !!introducedAgentData,
          hasGenericAgentData: !!genericAgentData,
          hasPendingAgentData: !!pendingAgentData,
          isAgentFormPostedInCookie,
          hasRecommendedAgents,
        })
      );
    }
  }, [
    isAgentFormPostedInCookie,
    hasRecommendedAgents,
    connectedAgentFetchStatus,
    introducedAgentData,
    pendingAgentData,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetContactAgentStatus());
    };
  }, []);

  function handlePostAgentSelection(
    formValues: ReferralFormValues,
    agentId: string | null
  ) {
    clearIsAgentFormPostedInCookie(userId);
    dispatch(deleteAgentSelection());
    setWasAgentJustSelected(true);
    if (agentId) {
      if (!lenderAgentData) {
        throw new Error('Attempting to select invalid lenderAgentData');
      }
      dispatch(
        postLenderAgentSelection({
          formValues,
          agentId,
          agentData: lenderAgentData,
        })
      );
    } else {
      if (!previousAgentData) {
        throw new Error('Attempting to select invalid previousAgentData');
      }
      dispatch(
        postPreviousAgentSelection({
          formValues,
          agentData: previousAgentData,
        })
      );
    }
    setAgentDisplayType('pending');
  }

  function handleSelectAgentClick(agentId: string | null = null) {
    reportYourTeamEvent(
      `click_your_team_use_this_agent_button_${
        agentId ? 'lender_recommended' : 'previous'
      }_agent`,
      {}
    );

    /* Users coming from SSO/SAML flow may have incomplete user info */
    if (!user?.first_name || !user?.last_name || !user?.phone || !user?.email) {
      setAgentDisplayType('userForm');
      setSelectedAgentId(agentId);
    } else {
      handlePostAgentSelection(
        {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          phone: user.phone,
        },
        agentId
      );
    }
  }

  const PendingStateHeadline = `${
    wasAgentJustSelected ? 'Thanks! ' : ''
  }A ComeHome concierge will be reaching out soon.`;

  return (
    <PulseLoader
      isLoading={
        !introducedAgentData &&
        !pendingAgentData &&
        (connectedAgentFetchStatus === STATUSES.LOADING ||
          previousAgentFetchStatus === STATUSES.LOADING ||
          lenderAgentFetchStatus === STATUSES.LOADING)
      }
      style={{ height: 200 }}
    >
      <>
        {agentDisplayType === 'userForm' && (
          <>
            <div role="heading" aria-level={1} className={theme.HeroText}>
              To be paired with this agent, please complete the following
              required fields.
            </div>
            <PropertyContactFormContainer
              horizontal
              theme={theme}
              submitBtnText="Done"
              shouldHideCommentsSection
              isShowingMobileCloseButton={false}
              onSubmit={(userFormValues) => {
                reportYourTeamEvent('click_your_team_user_form_submit', {
                  buttonCopy: 'Done',
                  userFormValues,
                });
                handlePostAgentSelection(userFormValues, selectedAgentId);
                setSelectedAgentId(null);
              }}
            />
            <TextButton
              className={theme.BackLink}
              style={{ color: linkColor }}
              dataHcName="your-team-user-form-back-link"
              data-event-name="click_your_team_user_form_back_link"
              onClick={() => setAgentDisplayType('select')}
            >
              Back
            </TextButton>
          </>
        )}
        {agentDisplayType === 'agentForm' && (
          <>
            {!isAgentFormPostedInCookie && (
              <>
                <FindAgentForm
                  theme={theme}
                  submitBtnText="Request agent"
                  dataEventName="click_find_agent_your_team"
                  pageHeaderText="We'll connect you with top agents in your area."
                  shouldDisplayBuyingOrSellingSection
                  handleAgentFormSubmitted={() => {
                    dispatch(deleteAgentSelection());
                    setIsAgentFormPostedInCookie(userId);
                    setAgentDisplayType('pending');
                  }}
                />
                {
                  /* "Back" CTA - Only show this when introduced agent and has no recommendedAgents to allow users to go "back" to the `introduced` screen */
                  !!introducedAgentData && !hasRecommendedAgents && (
                    <TextButton
                      className={theme.ChooseAnotherAgentCTA}
                      dataHcName="find-agent-back-button"
                      data-event-name="click_your_team_find_agent_back_button"
                      style={{ color: linkColor }}
                      onClick={() => {
                        setAgentDisplayType('introduced');
                      }}
                    >
                      Back
                    </TextButton>
                  )
                }
                {hasRecommendedAgents && (
                  <ChooseAnotherAgentCTA
                    agentDisplayType={agentDisplayType}
                    setAgentDisplayType={setAgentDisplayType}
                  />
                )}
              </>
            )}
          </>
        )}
        {agentDisplayType === 'pending' && (
          <>
            {isAgentFormPostedInCookie && (
              <div className={theme.FindAgentConfirmationText}>
                {PendingStateHeadline}
              </div>
            )}
            {!isAgentFormPostedInCookie && (
              <div
                className={classNames(theme.PendingAgent, {
                  [theme.PendingAgentWithoutCTA]: !hasRecommendedAgents,
                })}
              >
                <div className={theme.PendingAgentHeader}>
                  {PendingStateHeadline}
                </div>
                <FindAgentAvatarDark
                  role="img"
                  className={theme.Photo}
                  aria-label="Selected agent avatar"
                />
                <div className={theme.Name}>{pendingAgentData?.full_name}</div>
                <div className={theme.AgentDetail}>
                  {getStringWithDelimiter(
                    [
                      pendingAgentData?.brokerage_name,
                      pendingAgentData?.license_number &&
                        `License #${pendingAgentData?.license_number}`,
                    ],
                    '·'
                  )}
                </div>
                <div className={theme.AgentDetail}>
                  {getStringWithDelimiter(
                    [
                      formatPhoneNumber(pendingAgentData?.phone ?? ''),
                      pendingAgentData?.email,
                    ],
                    '·'
                  )}
                </div>
              </div>
            )}
            {hasRecommendedAgents && (
              <ChooseAnotherAgentCTA
                agentDisplayType={agentDisplayType}
                setAgentDisplayType={setAgentDisplayType}
              />
            )}
          </>
        )}
        {agentDisplayType === 'generic' && (
          <>
            {contactAgentMessageStatus === STATUSES.SUCCESS ? (
              <MessageSuccess
                dataHcName="your-team-agent-email-success"
                onLinkClick={() => dispatch(resetContactAgentStatus())}
              />
            ) : (
              <>
                <div className={theme.AgentDetailContainer}>
                  <div className={theme.AgentDetail}>
                    {getStringWithDelimiter(
                      [
                        formatPhoneNumber(genericAgentData?.phone ?? ''),
                        genericAgentData?.email,
                      ],
                      '·'
                    )}
                  </div>
                </div>
                <YourTeamCTAs
                  ctaTextArr={[
                    { text: yourTeamCTAConfig?.agent_cta_1 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_2 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_3 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_4 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_5 ?? '' },
                  ]}
                  dataHcName="your-team-agent-button-options"
                  onSubmit={(selectedCta: string) => {
                    if (!!genericAgentData) {
                      dispatch(
                        /* This is a catch-all for safety. User should be prompted to login when clicking
                         * one of the message buttons in <YourTeamCTAs>, so should always be logged in at
                         * submit time */
                        ensureLoggedInThenAction(
                          postContactAgent({
                            selectedCta,
                            id: genericAgentData.id,
                          })
                        )
                      );
                    } else {
                      throw new Error(
                        `Attempting to contact invalid agent: ${JSON.stringify(
                          genericAgentData
                        )}`
                      );
                    }
                  }}
                />
              </>
            )}
          </>
        )}
        {agentDisplayType === 'introduced' && (
          <>
            {contactAgentMessageStatus === STATUSES.SUCCESS ? (
              <MessageSuccess
                dataHcName="your-team-agent-email-success"
                onLinkClick={() => dispatch(resetContactAgentStatus())}
              />
            ) : (
              <>
                <div className={theme.AgentDetailContainer}>
                  <div className={theme.AgentDetail}>
                    {getStringWithDelimiter(
                      [
                        introducedAgentData?.brokerage_name,
                        introducedAgentData?.license_number &&
                          `License #${introducedAgentData?.license_number}`,
                      ],
                      '·'
                    )}
                  </div>
                  <div className={theme.AgentDetail}>
                    {getStringWithDelimiter(
                      [
                        formatPhoneNumber(introducedAgentData?.phone ?? ''),
                        introducedAgentData?.brokerage_email,
                      ],
                      '·'
                    )}
                  </div>
                </div>
                <YourTeamCTAs
                  ctaTextArr={[
                    { text: yourTeamCTAConfig?.agent_cta_1 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_2 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_3 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_4 ?? '' },
                    { text: yourTeamCTAConfig?.agent_cta_5 ?? '' },
                  ]}
                  dataHcName="your-team-agent-button-options"
                  onSubmit={(selectedCta: string) => {
                    if (introducedAgentData?.id) {
                      dispatch(
                        /* This is a catch-all for safety. User should be prompted to login when clicking
                         * one of the message buttons in <YourTeamCTAs>, so should always be logged in at
                         * submit time */
                        ensureLoggedInThenAction(
                          postContactAgent({
                            selectedCta,
                            id: introducedAgentData.id,
                          })
                        )
                      );
                    } else {
                      throw new Error(
                        `Attempting to contact invalid agent: ${JSON.stringify(
                          introducedAgentData
                        )}`
                      );
                    }
                  }}
                />
              </>
            )}
            {contactAgentMessageStatus !== STATUSES.SUCCESS &&
              isReferralServicesEnabled &&
              isLoggedIn && (
                <ChooseAnotherAgentCTA
                  agentDisplayType={agentDisplayType}
                  setAgentDisplayType={setAgentDisplayType}
                />
              )}
          </>
        )}
        {agentDisplayType === 'select' && (
          <>
            <div className={theme.SelectAgentHeader}>
              Which agent would you like to use?
            </div>
            <div className={theme.SelectAgentBody}>
              {previousAgentData && (
                <div className={theme.SelectAgentOption}>
                  <FindAgentAvatarDark
                    role="img"
                    className={theme.Photo}
                    aria-label="Select agent avatar"
                  />
                  <div className={theme.SelectAgentOptionInfo}>
                    <div className={theme.SelectAgentOptionHeader}>
                      {SELECT_PREVIOUS_AGENT_HEADER_COPY}
                    </div>
                    <div className={theme.SelectAgentOptionName}>
                      {previousAgentData?.name}
                    </div>
                  </div>
                  <PillButton
                    className={theme.UseThisAgentCTA}
                    ariaLabel="Use this agent"
                    onClick={(_) => handleSelectAgentClick()}
                  >
                    {SELECT_AGENT_BUTTON_COPY}
                  </PillButton>
                </div>
              )}
              {lenderAgentData && (
                <div className={theme.SelectAgentOption}>
                  <FindAgentAvatarDark
                    role="img"
                    className={theme.Photo}
                    aria-label="Select agent avatar"
                  />
                  <div className={theme.SelectAgentOptionInfo}>
                    <div className={theme.SelectAgentOptionHeader}>
                      {SELECT_LENDER_AGENT_HEADER_COPY}
                    </div>
                    <div className={theme.SelectAgentOptionName}>
                      {lenderAgentData?.first_name} {lenderAgentData?.last_name}
                    </div>
                  </div>
                  <PillButton
                    className={theme.UseThisAgentCTA}
                    ariaLabel="Use this agent"
                    onClick={() => handleSelectAgentClick(lenderAgentData?.id)}
                  >
                    {SELECT_AGENT_BUTTON_COPY}
                  </PillButton>
                </div>
              )}
            </div>
            <PillButton
              className={theme.GetPairedWithAnAgent}
              dataHcName="get-paired-with-an-agent-button"
              ariaLabel="Get paired with an agent"
              onClick={() => {
                reportYourTeamEvent(
                  'click_your_team_get_paired_with_an_agent',
                  {
                    buttonCopy: GET_PAIRED_WITH_AN_AGENT_CTA_COPY,
                  }
                );
                setAgentDisplayType(
                  isAgentFormPostedInCookie ? 'pending' : 'agentForm'
                );
              }}
            >
              {GET_PAIRED_WITH_AN_AGENT_CTA_COPY}
            </PillButton>
          </>
        )}
      </>
    </PulseLoader>
  );
}
