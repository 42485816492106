import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useReportYourTeamEvent } from '@client/components/YourTeam/utils/your-team.utils';
import theme from '@client/css-modules/YourTeam/YourTeamTabs.css';
import { useCobrandStyles } from '@client/hooks/cobrand-styles.hooks';
import {
  useIsLOTabDisabled,
  useYourTeamAgentData,
  useYourTeamModalState,
} from '@client/hooks/your-team.hooks';
import WellsFargoLogo from '@client/inline-svgs/cobrand/wellsfargo/wells-fargo-logo';
import FindAgentAvatar from '@client/inline-svgs/find-agent-avatar';
import FindAgentAvatarDark from '@client/inline-svgs/find-agent-avatar-dark';
import {
  getCobrandDisplayName,
  getIsWellsFargoCobrand,
  getYourTeam,
} from '@client/store/selectors/cobranding.selectors';
import { getLoanOfficerInfo } from '@client/store/selectors/loan-officer.selectors';
import {
  selectYourTeamModalType,
  setYourTeamModalType,
} from '@client/store/slices/your-team.slice';
import { onEnterOrSpaceKey } from '@client/utils/accessibility.utils';

export default function YourTeamTabs() {
  const dispatch = useDispatch();

  const reportYourTeamEvent = useReportYourTeamEvent();
  const yourTeamModalType = useSelector(selectYourTeamModalType);
  const loData = useSelector(getLoanOfficerInfo);
  const cobrandName = useSelector(getCobrandDisplayName);
  const yourTeam = useSelector(getYourTeam);
  const isWellsFargoCobrand = useSelector(getIsWellsFargoCobrand);
  const isLOTabDisabled = useIsLOTabDisabled();
  const isLOTabEnabled = !isLOTabDisabled;
  const { activePrimaryColor } = useCobrandStyles();
  const { isAgentTabEnabled } = useYourTeamModalState();
  const { introducedAgentData, genericAgentData, pendingAgentData } =
    useYourTeamAgentData();

  const loPhotoUrl = loData?.photoUrl;
  const loFullName = loData?.fullName;
  const isLOType = yourTeamModalType === 'lo';
  const isAgentType = yourTeamModalType === 'agent';
  const agentFullName =
    introducedAgentData?.first_name && introducedAgentData?.last_name
      ? `${introducedAgentData?.first_name} ${introducedAgentData?.last_name}`
      : genericAgentData?.full_name
        ? genericAgentData?.full_name
        : (pendingAgentData?.full_name ?? null);

  const [hasLOImgError, setHasLOImgError] = useState(true);

  useEffect(() => {
    if (loPhotoUrl) {
      let image = new Image();
      image.onload = function () {
        setHasLOImgError(false);
      };

      image.onerror = function () {
        setHasLOImgError(true);
      };
      image.src = loPhotoUrl;
    }
  }, [loPhotoUrl]);

  function getLOCardImg() {
    if (isWellsFargoCobrand) {
      return (
        <div
          className={classNames(theme.PhotoColumn, {
            [theme.PhotoColumnActiveNav]: isLOType,
          })}
        >
          <WellsFargoLogo />
        </div>
      );
    } else if (loPhotoUrl && !hasLOImgError) {
      return (
        <div
          className={classNames(theme.PhotoColumn, {
            [theme.PhotoColumnActiveNav]: isLOType,
          })}
        >
          <img
            className={theme.Photo}
            src={loPhotoUrl}
            alt={loFullName}
            onError={() => setHasLOImgError(true)}
          />
        </div>
      );
    } else {
      return null;
    }
  }
  function getLOCardHeader() {
    if (loData) {
      return loFullName;
    } else if (isWellsFargoCobrand) {
      return null;
    } else {
      return cobrandName;
    }
  }
  function getLOTitle() {
    if (!loData) {
      return null;
    } else if (yourTeam?.lo_title && loData) {
      return yourTeam?.lo_title;
    } else if (isWellsFargoCobrand) {
      return loData ? 'Mortgage Consultant' : null;
    } else {
      return 'Loan Officer';
    }
  }
  function openAgentTab() {
    reportYourTeamEvent('click_your_team_find_an_agent_tab', {
      tabCopy: agentFullName ?? 'Find an agent',
    });
    dispatch(setYourTeamModalType({ modalType: 'agent' }));
  }

  const loCardImg = getLOCardImg();
  const loCardHeader = getLOCardHeader();
  const LOTabSubtext = getLOTitle();
  const LOTabContent = (
    <>
      {loCardImg && loCardImg}
      {loCardHeader && (
        <div
          role="heading"
          aria-level={1}
          className={theme.Label}
          style={{ ...(isLOType && { color: activePrimaryColor }) }}
        >
          {loCardHeader}
        </div>
      )}
      {LOTabSubtext && (
        <div
          className={theme.SubText}
          style={{ ...(isLOType && { color: activePrimaryColor }) }}
        >
          {LOTabSubtext}
        </div>
      )}
    </>
  );
  const AgentTabSubText =
    (introducedAgentData || genericAgentData || pendingAgentData) &&
    'Your Agent';
  const AgentTabContent = (
    <>
      <div
        className={classNames(theme.PhotoColumn, {
          [theme.PhotoColumnActiveNav]: isAgentType,
        })}
      >
        {introducedAgentData?.headshot ? (
          <img
            className={theme.Photo}
            src={introducedAgentData.headshot}
            alt={`${introducedAgentData.first_name} ${introducedAgentData.last_name}`}
            onError={() => setHasLOImgError(true)}
          />
        ) : genericAgentData?.headshot ? (
          <img
            className={theme.Photo}
            src={genericAgentData.headshot}
            alt={genericAgentData.full_name}
            onError={() => setHasLOImgError(true)}
          />
        ) : !isLOTabEnabled ? (
          <FindAgentAvatar
            role="img"
            aria-label="Selected find an agent avatar"
            className={theme.FindAgentAvatar}
          />
        ) : isAgentType ? (
          <FindAgentAvatar
            role="img"
            aria-label="Selected find an agent avatar"
            className={theme.FindAgentAvatar}
          />
        ) : (
          <FindAgentAvatarDark
            role="img"
            className={theme.FindAgentAvatar}
            aria-label="Unselected find an agent avatar"
          />
        )}
      </div>
      <div
        role="heading"
        aria-level={1}
        className={theme.Label}
        style={{ ...(isAgentType && { color: activePrimaryColor }) }}
      >
        {agentFullName ?? 'Find an agent'}
      </div>
      {AgentTabSubText && (
        <div
          className={theme.SubText}
          style={{ ...(isAgentType && { color: activePrimaryColor }) }}
        >
          {AgentTabSubText}
        </div>
      )}
    </>
  );

  return (
    <>
      {isAgentTabEnabled && isLOTabEnabled && (
        <Tabs.Root
          onValueChange={(value: string) =>
            value === 'lo-tab'
              ? dispatch(setYourTeamModalType({ modalType: 'lo' }))
              : openAgentTab()
          }
          className={theme.YourTeamTabs}
          data-hc-name="your-team-tab-list"
        >
          <Tabs.List className={theme.LOAndAgentCardTopNavTabList}>
            <Tabs.Trigger
              value="lo-tab"
              aria-label={'View Loan Office card'}
              aria-selected={isLOType}
              data-hc-name="your-team-header-lo-tab"
              onKeyDown={onEnterOrSpaceKey(() =>
                dispatch(setYourTeamModalType({ modalType: 'lo' }))
              )}
              className={classNames(theme.NavButton, theme.LONavButton, {
                [theme.ActiveNav]: isLOType,
              })}
            >
              {LOTabContent}
            </Tabs.Trigger>
            <Tabs.Trigger
              value="agent-tab"
              aria-label={'View Agent card'}
              aria-selected={isAgentType}
              data-hc-name="your-team-header-agent-tab"
              onKeyDown={onEnterOrSpaceKey(openAgentTab)}
              className={classNames(theme.NavButton, theme.AgentNavButton, {
                [theme.ActiveNav]: isAgentType,
              })}
            >
              {AgentTabContent}
            </Tabs.Trigger>
          </Tabs.List>
        </Tabs.Root>
      )}
      {isLOTabEnabled && !isAgentTabEnabled && (
        <div className={theme.YourTeamTabs} data-hc-name="your-team-header-lo">
          <div
            className={classNames(
              theme.NavButton,
              theme.LONavButton,
              theme.ActiveNav,
              theme.OneCardHeader
            )}
          >
            {LOTabContent}
          </div>
        </div>
      )}
      {isAgentTabEnabled && !isLOTabEnabled && (
        <div
          className={theme.YourTeamTabs}
          data-hc-name="your-team-header-agent"
        >
          <div
            className={classNames(
              theme.NavButton,
              theme.AgentNavButton,
              theme.ActiveNav,
              theme.OneCardHeader
            )}
          >
            {AgentTabContent}
          </div>
        </div>
      )}
    </>
  );
}
